<template>
  <button>
    <div class="button-text">
      <slot name="text"></slot>
    </div>
    <div class="button-icon">
      <slot name="icon"></slot>
    </div>
  </button>
</template>

<script>
export default {
  name: "CustomButton",
};
</script>

<style lang="scss">
@import "@/assets/styles/style.scss";

button {
  background-color: $primary-color;
  color: #fff;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-radius: $borad;
  padding: 0;
  overflow: hidden;
  font-family: "BankGothic", sans-serif;
  font-size: 20px;

  .button-text {
    padding: 10px 20px;
  }

  .button-icon {
    width: 50px;
    height: 80px;
    fill: #fff;
    background-color: $accent-color;
    padding: 16px;

    .icon {
      width: 100%;
      height: 100%;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
