<template>
  <div>
    <div class="logo">
      <OpttoolLogo />
    </div>

    <div class="text">
      Willkommen zum OptTool von Teudeloff! Optimieren Sie Ihre
      Schrauben-Herstellung mit präzisen Berechnungen. Starten Sie jetzt und
      erleben Sie Effizienz auf neuem Niveau.
    </div>

    <div class="button-list">
      <CustomButton @click="$router.push('/neuer-versuch')">
        <template #text>Neuer Versuch</template>
        <template #icon>
          <IconPlus />
        </template>
      </CustomButton>
      <CustomButton @click="$router.push('/versuche')">
        <template #text>Meine Versuche</template>
        <template #icon>
          <IconChevronRight />
        </template>
      </CustomButton>
      <CustomButton @click="$router.push('/chat')">
        <template #text>Support</template>
        <template #icon>
          <IconChevronRight />
        </template>
      </CustomButton>
    </div>
  </div>
</template>

<script>
import OpttoolLogo from "@/components/icons/OpttoolLogo.vue";
import CustomButton from "@/components/elements/CustomButton.vue";
import IconChevronRight from "@/components/icons/IconChevronRight.vue";
import IconPlus from "@/components/icons/IconPlus.vue";

export default {
  name: "HomeView",
  components: {
    OpttoolLogo,
    CustomButton,
    IconPlus,
    IconChevronRight,
  },
};
</script>

<style lang="scss" scoped>
.logo {
  margin: 30px 0;
  width: 100%;
  text-align: center;

  img {
    max-width: 380px;
  }
}

.text {
  text-align: center;
  font-size: 20px;
  margin: 0 0 60px;
}

.button-list {
  button {
    & + button {
      margin-top: 15px;
    }
  }
}
</style>
