import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import axios from "axios";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: { title: "Startseite" },
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
    meta: { title: "Startseite" },
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/LoginView.vue"),
    meta: { title: "Anmeldung" },
  },
  {
    path: "/rechner",
    name: "rechner",
    component: () =>
      import(/* webpackChunkName: "rechner" */ "../views/CalculatorView.vue"),
    meta: { title: "Rechner" },
  },
  {
    path: "/neuer-versuch",
    name: "neuerVersuch",
    component: () =>
      import(
        /* webpackChunkName: "neuerVersuch" */ "../views/calculator/NewTryView.vue"
      ),
    meta: { title: "Neuer Versuch" },
  },
  {
    path: "/antriebsart",
    name: "antriebsart",
    component: () =>
      import(
        /* webpackChunkName: "antriebsart" */ "../views/calculator/DriveTypeView.vue"
      ),
    meta: { title: "Antriebsart" },
  },
  {
    path: "/schraubenkopfform",
    name: "schraubenkopfform",
    component: () =>
      import(
        /* webpackChunkName: "schraubenkopfform" */ "../views/calculator/SkewHeadShapeView.vue"
      ),
    meta: { title: "Schraubenkopfform" },
  },
  {
    path: "/neuen-versuch-anlegen",
    name: "neuenVersuchAnlegen",
    component: () =>
      import(
        /* webpackChunkName: "neuenVersuchAnlegen" */ "../views/calculator/NewTryForm.vue"
      ),
    meta: { title: "Neuen Versuch anlegen" },
  },
  {
    path: "/weitere-versuchsparameter",
    name: "weitereVersuchsparameter",
    component: () =>
      import(
        /* webpackChunkName: "weitereVersuchsparameter" */ "../views/calculator/FurtherTestParameter.vue"
      ),
    meta: { title: "Weitere Versuchsparameter" },
  },
  {
    path: "/versuchsergebnis",
    name: "versuchsergebnis",
    component: () =>
      import(
        /* webpackChunkName: "versuchsergebnis" */ "../views/calculator/TestResult.vue"
      ),
    meta: { title: "Versuchsergebnis" },
  },
  {
    path: "/versuche",
    name: "versuche",
    component: () =>
      import(/* webpackChunkName: "versuche" */ "../views/MyAttemptsView.vue"),
    meta: { title: "Meine Versuche" },
  },
  {
    path: "/versuch/:id",
    name: "versucheDetail",
    component: () =>
      import(
        /* webpackChunkName: "versucheDetail" */ "../views/AttemptDetail.vue"
      ),
    meta: { title: "Versuch" },
  },
  {
    path: "/chat",
    name: "chat",
    component: () =>
      import(/* webpackChunkName: "chat" */ "../views/ChatView.vue"),
    meta: { title: "Support Chat" },
  },
  {
    path: "/impressum",
    name: "impressum",
    component: () =>
      import(
        /* webpackChunkName: "impressum" */ "../views/pages/ImprintView.vue"
      ),
    meta: { title: "Impressum" },
  },
  {
    path: "/datenschutz",
    name: "datenschutz",
    component: () =>
      import(
        /* webpackChunkName: "datenschutz" */ "../views/pages/DataPrivacyView.vue"
      ),
    meta: { title: "Datenschutz" },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // Setzen des Dokumententitels
  document.title = to.meta.title ? to.meta.title : "Teudeloff OptTool";

  const token = localStorage.getItem("token");

  // Prüfen, ob die aktuelle Route die Login-Seite ist
  if (to.path === "/login") {
    // Wenn ein Token vorhanden ist, leiten Sie den Nutzer auf die Startseite um, oder führen Sie eine Gültigkeitsprüfung durch
    if (token) {
      // Optional: Token Gültigkeit prüfen
      axios
        .post("https://app.webixel-werbeagentur.de/api/token-verify.php", {
          token,
        })
        .then((response) => {
          if (response.data.isValid) {
            next("/");
          } else {
            // Token ist ungültig, also bleiben wir auf der Login-Seite
            next();
          }
        })
        .catch(() => {
          // Bei einem Fehler in der API-Anfrage, bleiben wir auf der Login-Seite
          next();
        });
    } else {
      // Kein Token, also ist es in Ordnung, auf der Login-Seite zu bleiben
      next();
    }
  } else {
    // Für alle anderen Routen, prüfen wir, ob ein Token vorhanden ist
    if (!token) {
      // Kein Token gefunden, Nutzer wird zur Login-Seite umgeleitet
      next("/login");
    } else {
      // Token gefunden, führen Sie eine Gültigkeitsprüfung durch
      axios
        .post("https://app.webixel-werbeagentur.de/api/token-verify.php", {
          token,
        })
        .then((response) => {
          if (response.data.isValid) {
            // Token ist gültig, Fortsetzung der Navigation
            next();
          } else {
            // Token ist ungültig, Umleitung zur Login-Seite
            next("/login");
          }
        })
        .catch(() => {
          // Bei einem Fehler, Nutzer zur Login-Seite umleiten
          next("/login");
        });
    }
  }
});

export default router;
