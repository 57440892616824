import { createStore } from "vuex";

export default createStore({
  state: {
    userId: null,
    token: null,
    currentAttempt: {},
  },
  getters: {},
  mutations: {
    setFromLocalStorage(state, payload) {
      state.userId = payload.userId;
      state.token = payload.token;
    },
    updateCurrentAttempt(state, payload) {
      state.currentAttempt = { ...state.currentAttempt, ...payload };
    },
    setCurrentAttemptDrawingFile(state, file) {
      if (!state.currentAttempt) {
        state.currentAttempt = {};
      }
      state.currentAttempt.drawingFile = file;
    },
  },
  actions: {
    initStore({ commit }) {
      const stateDO = {
        userId: localStorage.getItem("user") || null,
        token: localStorage.getItem("token") || null,
      };

      if (stateDO) commit("setFromLocalStorage", stateDO);
    },
  },
  modules: {},
});
