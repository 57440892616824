<template>
  <img src="@/assets/img/Opttool-Logo.png" alt="Logo" />
</template>

<script>
export default {
  name: "LogoComponent",
};
</script>

<style lang="scss" scoped>
img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}
</style>
